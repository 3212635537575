@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@800&display=swap');

:root {
  background-color: rgb(30, 30, 30);
}

.App {
  text-align: center;
}

h1 {
    font-family: "Roboto Slab", serif;
}

#tsparticles {
  height: 100vh;
  width: 100vw;
  position: fixed;
}

.main-container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  overflow-y: scroll;
  color: black;
}


@media only screen and (min-width:670px) {
  .main-container::-webkit-scrollbar {
    width: 7px;
    /* width of the entire scrollbar */
  }
  /* .main-container::-webkit-scrollbar-thumb {
    background-color: blue;
    border-radius: 20px;
  } */
}
